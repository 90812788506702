import { useCallback, useEffect } from 'react';

import { apiSignOut } from '../../api/auth';
import { useAuthContext } from '../../contexts/auth';
import { AuthUser } from '../../models/auth';

const Logout = () => {
  const { setUser } = useAuthContext();

  const signOut = useCallback(async () => {
    setUser({} as AuthUser);
    await apiSignOut();
  }, [setUser]);

  useEffect(() => {
    signOut();
  }, [signOut]);

  return <>You are being logged out...</>;
};

export default Logout;
