import { apiFederatedSignIn } from '../../api/auth';
import { Container } from '../../components/GoogleMap/Map.styles';
import { Header } from '../../components/Header/Header';
import { ButtonContainer, ContactButton } from '../../components/NotFound/NotFound.styles';

const Login = () => {
  return (
    <>
      <Header />
      <Container>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            flexDirection: 'column',
            height: '100%',
          }}>
          <ButtonContainer>
            <ContactButton onClick={apiFederatedSignIn} variant='body1'>
              Login
            </ContactButton>
          </ButtonContainer>
        </div>
      </Container>
    </>
  );
};

export default Login;
