import React from 'react';
import { SelectFileButton, SelectFileText, UploadIcon } from './ChooseFile.styles';
import uploadIcon from '../../assets/icons/upload.svg';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';

export interface FileUploadProps {
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void;
}

export const ChooseFile = ({ onDrop }: FileUploadProps) => {
  const intl = useIntl();
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <SelectFileButton {...getRootProps()} variant={'outlined'}>
      <input {...getInputProps()} />
      <UploadIcon alt="" src={uploadIcon} />
      <SelectFileText>
        {intl.formatMessage({
          id: 'cta.select_file',
          defaultMessage: 'Select file',
        })}
      </SelectFileText>
    </SelectFileButton>
  );
};
