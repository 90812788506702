import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

import { AuthUser } from '../models/auth';

export const apiFederatedSignIn = async () => await Auth.federatedSignIn();
export const apiSignOut = async () =>
  await Auth.signOut({
    global: false,
  });
export const apiCheckCurrentUser = async (): Promise<CognitoUser & AuthUser> =>
  await Auth.currentAuthenticatedUser();
