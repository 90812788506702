import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { apiCheckCurrentUser } from '../api/auth';
import { AuthUser } from '../models/auth';

type AuthContextData = {
  user: AuthUser;
  setUser: (user: AuthUser) => void;
  isAuthenticated: boolean;
  isLoading: boolean;
};

const AuthContext = createContext({} as AuthContextData);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<AuthUser>({} as AuthUser);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const checkIsAuthenticated = useCallback(async () => {
    try {
      const cognitoUser = await apiCheckCurrentUser();

      if (cognitoUser?.username) {
        setUser({
          username: cognitoUser.username,
          userDataKey: cognitoUser.userDataKey,
          groups: (
            cognitoUser.getSignInUserSession()?.getIdToken().payload['cognito:groups'] || []
          ).map((group: string) => group.toLowerCase()),
        });

        setIsAuthenticated(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    checkIsAuthenticated();
  }, [checkIsAuthenticated]);

  const memoValue = useMemo(
    () => ({ user, setUser, isAuthenticated, isLoading }),
    [user, isAuthenticated, isLoading],
  );

  return <AuthContext.Provider value={memoValue}>{children}</AuthContext.Provider>;
};

const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }

  return context;
};

export { AuthContext, AuthProvider, useAuthContext };
