import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { apiCheckCurrentUser } from '../../api/auth';
import { useAuthContext } from '../../contexts/auth';

const SignIn = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const from = location.state?.from?.pathname || '/';
  const { setUser } = useAuthContext();
  const { formatMessage } = useIntl();

  const checkCurrentUser = useCallback(async () => {
    try {
      const user = await apiCheckCurrentUser();

      setUser({
        username: user.username,
        userDataKey: user.userDataKey,
        groups: user.getSignInUserSession()?.getIdToken().payload['cognito:groups'] || [],
      });

      navigate(from, { replace: true });
    } catch (error) {
      alert(formatMessage({ id: 'error.signin', defaultMessage: 'Error Signing In' }));
      navigate('/login', { replace: true });
    }
  }, [setUser, navigate, from, formatMessage]);

  useEffect(() => {
    checkCurrentUser();
  }, [checkCurrentUser]);

  return <></>;
};

export default SignIn;
