import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Marker } from '@react-google-maps/api';
import { StudioSelectors } from '../../store/studio/studio.selectors';
import { StudioModelFiltered } from '../../models/StudioModel';
import { getCategoryById, getPartnerPath } from '../../store/studio/studio.utils';
import { getLanguage } from '../../utils/language.utils';

const CustomMarker = ({
  studio,
  idx,
  isHoverOrSelect,
  isHighlighted,
}: {
  studio: StudioModelFiltered;
  idx: number;
  isHoverOrSelect: boolean;
  isHighlighted?: boolean;
}) => {
  const markersRef = useRef<Marker[]>([]);
  const visibleStudios = useSelector(StudioSelectors.getVisibleStudios);
  const studioGroups = useSelector(StudioSelectors.getStudioGroups);
  const selectedStudio = useSelector(StudioSelectors.getSelected);
  const allCategories = useSelector(StudioSelectors.getCategories);
  const category = getCategoryById(allCategories, studio.categoryPrimary);
  const language = getLanguage();

  const handleLocalMouseOver = (idx: number, studio: StudioModelFiltered) => {
    if (studioGroups.length > 0) {
      const marker = markersRef.current[idx];
      const category = getCategoryById(allCategories, studio.categoryPrimary);

      marker.marker?.setIcon({
        url: category.mapMarkerUrlSelected || '',
        scaledSize: new window.google.maps.Size(45, 45),
      });
      marker.marker?.setZIndex(2);
    }
  };

  const handleLocalMouseOut = (idx: number, studio: StudioModelFiltered) => {
    if (studioGroups.length > 0) {
      const marker = markersRef.current[idx];
      const isSelected = selectedStudio?.publicId === studio.publicId;
      const category = getCategoryById(allCategories, studio.categoryPrimary);

      marker.marker?.setIcon({
        url: category.mapMarkerUrlActive || '',
        scaledSize: new window.google.maps.Size(40, 40),
      });
      marker.marker?.setZIndex(1);
    }
  };

  const handleClick = (studio: StudioModelFiltered) => {
    const path = getPartnerPath(studio, language, allCategories);

    const a = document.createElement('a');
    a.href = `${process.env.REACT_APP_HANSEFIT_WEBSITE}${path}`;
    a.target = '_blank';
    a.click();
  };

  return (
    <Marker
      zIndex={isHoverOrSelect ? 2 : 1}
      ref={el => (markersRef.current[idx] = el!)}
      onClick={studioGroups.length > 0 ? () => handleClick(studio) : undefined}
      onMouseOver={visibleStudios.length > 0 ? () => handleLocalMouseOver(idx, studio) : undefined}
      onMouseOut={visibleStudios.length > 0 ? () => handleLocalMouseOut(idx, studio) : undefined}
      icon={{
        url: category.mapMarkerUrlActive || '',
        scaledSize: new window.google.maps.Size(
          isHoverOrSelect ? 45 : 40,
          isHoverOrSelect ? 44 : 40,
        ),
      }}
      position={{ lng: studio.coordLong, lat: studio.coordLat }}
    />
  );
};

export default CustomMarker;
