import { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuthContext } from '../../contexts/auth';

const RequireAuth = ({ children }: { children: ReactElement }) => {
  const location = useLocation();

  const { isAuthenticated, isLoading } = useAuthContext();

  if (isLoading) {
    return <>Loading...</>;
  }

  if (!isAuthenticated && !isLoading) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
